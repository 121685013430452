<template>
  <div class="modal-container">
    <modal-component v-show="show" :isScrollable="true">
      <template v-slot:header>
        <div class="display-flex">
          <div>Upload Banner</div>
          <p @click="turnModalOff()">x</p>
        </div>
      </template>
      <template v-slot:body>
        <div>
          <input type="file" @change="onFileChange" accept="image/*" />
          <button @click="uploadImage">Upload Image</button>
        </div>
        <div>
          <div v-for="banner in promotionalBannerList" :key="banner.imageUrl">
            <image-comp :imageUrl="banner.imageUrl" :classProp="[]" />
            <div>
              <toggle-switch
                :status="banner.active ? 'ACTIVE' : 'DEACTIVE'"
                @change="changeItemStatus($event, banner)"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="footer-class">
          <div class="action-button">
            <button-comp
              :value="'Upload Banner'"
              class="store-coupon-button"
              :height="'40px'"
              :width="'100%'"
              :color="'crimson'"
              @clickEvent="uploadImage()"
            ></button-comp>
          </div>
        </div>
      </template>
    </modal-component>
  </div>
</template>

<!-- <script src="https://unpkg.com/vue3-google-map"></script> -->
<script>
// import { errorAlert, infoAlert } from "@/utility";
// import { GoogleMap, Marker } from "vue3-google-map";

export default {
  data() {
    return {
      selectedDiscountFrequency: "",
      heading: "",
      content: "",
      hour: "",
      notificationList: [],
      restaurantList: [],
      selectedRestaurant: "",
      deductionType: "",
      promotionalBannerList: [],
    };
  },
  components: {},
  props: {
    show: { type: Boolean, default: true },
    prefill: { type: Object, default: null },
  },
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    this.getPromotionalBanners();
  },
  methods: {
    turnModalOff() {
      this.$emit("turnShowOrderRemoveModalOff");
    },
    async changeItemStatus(event, banner) {
      console.log(event, banner);

      await this.postApiCall("/superAdmin/setBannerStatus", {
        active: event === "ACTIVE" ? true : false,
        bannerId: banner._id,
      });
    },
    onFileChange(event) {
      this.imageFile = event.target.files[0];
    },
    async getPromotionalBanners() {
      this.promotionalBannerList = await this.getApiCall(
        "/superAdmin/getPromotionalBanners"
      );
    },
    async uploadImage() {
      console.log("HOlla");
      if (!this.imageFile) {
        this.errorAlert("No file selected");
        return;
      }

      // Validate file size (less than 100KB)
      if (this.imageFile.size > 100000) {
        this.errorAlert("File size must be less than 100KB");
        return;
      }

      // Validate aspect ratio (2:1)
      const image = new Image();
      image.src = URL.createObjectURL(this.imageFile);
      image.onload = async () => {
        const formData = new FormData();
        formData.append("file", this.imageFile);
        try {
          await this.uploadFile("/superAdmin/uploadBanner", formData);
        } catch (error) {
          this.errorAlert("An error occurred while uploading the image");
        }
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-card {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  border: 1px solid grey;
  padding: 16px;
  border-radius: 5px;

  .notification-card-right {
    display: flex;
    // flex-direction: column;
    // align-content: flex-end;
    align-items: end;
    flex-wrap: wrap;
  }
}
</style>
