<template>
  <div class="image-card">
    <!-- Skeleton Loader -->
    <div v-if="loading" :class="[...classProp]"></div>

    <!-- Actual Image -->
    <img
      :src="imageUrl"
      alt="Image"
      :class="[...classProp]"
      @load="onImageLoad"
      @loadstart="onLoadStart"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true, // Initial loading state
      //   imageUrl: "https://via.placeholder.com/300x200", // Image URL
    };
  },
  props: {
    imageUrl: { type: String },
    classProp: { type: String },
  },
  methods: {
    onImageLoad() {
      this.loading = false; // Hide skeleton once the image loads
    },
    onLoadStart() {},
  },
};
</script>

<style lang="scss" scoped>
/* Container */
.image-card {
}

/* Skeleton styles */
.skeleton-image {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 400% 100%;
  border-radius: 8px;
  animation: shimmer 1.5s infinite;
}

/* Shimmer animation */
@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

.icon {
  img {
    border: 0.1em white solid;
    // border-radius: 50%;
  }

  img.active {
    border: 0.1em red solid;
    // border-radius: 50%;
  }
}

.restaurant-image {
  width: 100%;
  // height: 100px;
  object-fit: cover;
}

.item-image {
  width: 100px;
  float: right;
  margin-left: 16px;
  border-radius: 5px;
}

.prev-order-restaurant-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.promotional-banner {
  border-radius: 5px;
  width: 100%;
}

.slide {
  // width: 100%;
  transition: opacity 1s ease-in-out;
}
</style>
