<template>
  <div class="super-admin-container">
    <div :style="{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }">
      <div class="admin-butts" :style="{ marginTop: '10px' }">
        <button-comp
          :value="'Goto Payout'"
          class="add-location-button"
          :id="'getOtpButton'"
          :height="'40px'"
          :width="'100%'"
          :color="'#7FBD4F'"
          @clickEvent="
            () => {
              $router.push('/payout-dashboard');
            }
          "
        ></button-comp>
      </div>
      <div class="admin-butts" :style="{ marginTop: '10px' }">
        <button-comp
          :value="'Goto Referral Payout'"
          class="add-location-button"
          :id="'getReferralPayout'"
          :height="'40px'"
          :width="'100%'"
          :color="'#7FBD4F'"
          @clickEvent="
            () => {
              showReferralPayoutModal = true;
            }
          "
        ></button-comp>
      </div>
      <div class="admin-butts" :style="{ marginTop: '10px' }">
        <button-comp
          :value="'Manage Notifications'"
          class="add-location-button"
          :id="'getReferralPayout'"
          :height="'40px'"
          :width="'100%'"
          :color="'#7FBD4F'"
          @clickEvent="
            () => {
              showManageNotifications = true;
            }
          "
        ></button-comp>
      </div>
      <div class="admin-butts" :style="{ marginTop: '10px' }">
        <button-comp
          :value="'Create Coupons'"
          class="add-location-button"
          :id="'addCouponButton'"
          :height="'40px'"
          :width="'100%'"
          :color="'#7FBD4F'"
          @clickEvent="
            () => {
              showCouponAddModal = true;
            }
          "
        ></button-comp>
      </div>
      <div class="admin-butts" :style="{ marginTop: '10px' }">
        <button-comp
          :value="'Set Restaurant To User'"
          class="add-location-button"
          :height="'40px'"
          :width="'100%'"
          :color="'#7FBD4F'"
          @clickEvent="
            () => {
              this.showRestaurantManager = true;
            }
          "
        ></button-comp>
      </div>
      <div class="admin-butts" :style="{ marginTop: '10px' }">
        <button-comp
          :value="'Get Current Active Orders'"
          class="add-location-button"
          :height="'40px'"
          :width="'100%'"
          :color="'#7FBD4F'"
          @clickEvent="
            () => {
              this.showRestaurantManager = true;
            }
          "
        ></button-comp>
      </div>
      <div class="admin-butts" :style="{ marginTop: '10px' }">
        <button-comp
          :value="'Restaurant Info Dashboard'"
          class="add-location-button"
          :height="'40px'"
          :width="'100%'"
          :color="'#7FBD4F'"
          @clickEvent="
            () => {
              $router.push('/restaurant-info-dashboard');
            }
          "
        ></button-comp>
      </div>
      <div class="admin-butts" :style="{ marginTop: '10px' }">
        <button-comp
          :value="'Upload Banner'"
          class="add-location-button"
          :height="'40px'"
          :width="'100%'"
          :color="'#7FBD4F'"
          @clickEvent="
            () => {
              this.showUploadBanner = true;
            }
          "
        ></button-comp>
      </div>
    </div>
    <div class="set-access-rights">
      <h3>Set Access rights to user</h3>
      <input-field
        type="text"
        @change-handler="
          ($events) => {
            this.phoneNumber = $events;
          }
        "
        placeholder="Enter Phone Number"
      />
      <button-comp @click="getUserDetails()">Get User Details</button-comp>

      <h2>User Access Rights:</h2>
      <div v-if="userDetail">
        <div
          v-for="(accessRight, index) in accessRightsList"
          :key="index"
          @click="setAccesRight(accessRight)"
        >
          <input
            type="checkbox"
            id="toggle"
            :checked="isRightChecked(accessRight)"
          />
          {{ accessRight.name }}
        </div>
      </div>
    </div>
    <set-manager-user-modal
      v-if="showRestaurantManager"
      @turnShowOrderRemoveModalOff="
        () => {
          this.showRestaurantManager = false;
        }
      "
    ></set-manager-user-modal>
    <referral-payout-modal
      v-if="showReferralPayoutModal"
      @turnShowOrderRemoveModalOff="
        () => {
          this.showReferralPayoutModal = false;
        }
      "
    ></referral-payout-modal>
    <manage-coupon-modal
      v-if="showCouponAddModal"
      @turnShowOrderRemoveModalOff="
        () => {
          this.showCouponAddModal = false;
        }
      "
    ></manage-coupon-modal>
    <manage-notification-modal
      v-if="showManageNotifications"
      @turnShowOrderRemoveModalOff="
        () => {
          this.showManageNotifications = false;
        }
      "
    ></manage-notification-modal>
    <banner-upload-modal
      v-if="showUploadBanner"
      @turnShowOrderRemoveModalOff="
        () => {
          this.showUploadBanner = false;
        }
      "
    ></banner-upload-modal>
  </div>
</template>

<script>
import SetManagerUserModal from "../modals/SetManagerUserModal.vue";
import ReferralPayoutModal from "../modals/ReferralPayoutModal.vue";
import ManageCouponModal from "../modals/ManageCouponModal.vue";
import ManageNotificationModal from "../modals/ManageNotificationModal.vue";
import BannerUploadModal from "../modals/BannerUploadModal.vue";

export default {
  components: {
    SetManagerUserModal,
    ReferralPayoutModal,
    ManageCouponModal,
    ManageNotificationModal,
    BannerUploadModal,
  },
  data() {
    return {
      phoneNumber: "",
      user: null,
      accessRightsList: [],
      userDetail: null,
      showRestaurantManager: false,
      showReferralPayoutModal: false,
      showManageNotifications: false,
      showCouponAddModal: false,
      showUploadBanner: false,
    };
  },
  async created() {
    let result = await this.getApiCall("/superAdmin/getAccessRights");
    this.accessRightsList = result;
  },
  methods: {
    async setAccesRight(accessRight) {
      await this.getApiCall("/user/editAccessRight", {
        phoneNumber: this.phoneNumber,
        accessRight,
      });
    },
    isRightChecked(toCheckRight) {
      let userRights = this.userDetail?.accessRights;
      return userRights.includes(toCheckRight.name);
    },
    async getUserDetails() {
      let userData = await this.getApiCall("/user/getUserdata", {
        phoneNumber: this.phoneNumber,
      });
      this.userDetail = userData;
    },

    // async fetchData() {
    //   console.log("Hello"); // Console "Hello" every time the API is called
    //   this.loading = true;
    //   this.error = null;
    //   try {
    //     const response = await this.getApiCall(
    //       "/superAdmin/autoApiInterval"
    //     );
    //     if (!response.ok) throw new Error("Failed to fetch data");

    //     const result = await response.json();
    //     this.data = result.data;
    //     this.loading = false;
    //   } catch (err) {
    //     this.error = err.message;
    //     this.loading = false;
    //   }
    // },
  },
  mounted() {
    // this.fetchData(); // Fetch data when the component is mounted
    // Set up auto-refresh every 5 seconds (5000ms)
    // this.interval = setInterval(this.fetchData, 5000);
  },
};
</script>

<style lang="scss" scoped>
.admin-butts {
  width: 50%;
}

.super-admin-container {
  margin: 16px 16px 0px 16px;
}

.toggle-button {
  display: inline-block;
  width: 60px;
  height: 30px;
  background-color: #ccc;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle-button:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s;
}

#toggle:checked + .toggle-button {
  background-color: #3cba54;
}

#toggle:checked + .toggle-button:before {
  transform: translateX(30px);
}
</style>
